<template>
  <div>
    <!--Table Container Card-->
    <order-source-add-new
      :is-add-order-source-sidebar-active.sync="isAddOrderSourceSidebarActive"
      @refetch-data="refetchData"
      v-if="isAddOrderSourceSidebarActive"
    />

    <edit-order-source
      :is-edit-order-source-sidebar-active.sync="isEditOrderSourceSidebarActive"
      :order-source="orderSourceRef"
      @refetch-data="refetchData"
      v-if="isEditOrderSourceSidebarActive"
    />
    <div style="display: flex; justify-content: flex-end; margin: 20px 0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="isAddOrderSourceSidebarActive = true"
        variant="primary"
      >
        {{ $t("Add order source") }}
      </b-button>
    </div>

    <b-card no-body class="mb-0 mt-10">
      <b-form class="p-1">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Search')" label-for="name">
              <b-form-input id="name" v-model="searchQuery" debounce="500" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-table
        ref="refOrderSourceListTable"
        class="position-relative"
        :items="fetchOrderSource"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!----<b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="setOrderSource(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="DeleteOrderSource(data.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>-->
          <b-button
            @click="setOrderSource(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="Edit2Icon" size="16" />
          </b-button>
          <b-button
            @click="DeleteOrderSource(data.item)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon mr-1"
          >
            <feather-icon icon="Trash2Icon" size="16" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrderSource"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import userOrderSourceList from "./useOrderSourceList";
import { ref } from "@vue/composition-api";
import OrderSourceAddNew from "./OrderSourceAddNew.vue";
import EditOrderSource from "./EditOrderSource.vue";
import Ripple from "vue-ripple-directive";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    OrderSourceAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditOrderSource,
    BFormGroup,
    BForm,

    vSelect,
  },
  methods: {
    async DeleteOrderSource(OrderSource) {
      const agreed = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to delete it?"),
        {
          title: this.$t("Please Confirm"),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (agreed) {
        try {
          await instance.delete(
            `/parameters/delete_order_Source/${OrderSource.id}`
          );
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("It has been deleted successfully"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },

  setup(props) {
    const isAddOrderSourceSidebarActive = ref(false);
    const isEditOrderSourceSidebarActive = ref(false);
    const orderSourceRef = ref({});

    const {
      fetchOrderSource,
      tableColumns,
      perPage,
      currentPage,
      totalOrderSource,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderSourceListTable,
      refetchData,
    } = userOrderSourceList();

    function setOrderSource(OrderSource) {
      isEditOrderSourceSidebarActive.value = true;
      orderSourceRef.value = OrderSource;
    }

    return {
      // Sidebar
      isAddOrderSourceSidebarActive,
      isEditOrderSourceSidebarActive,
      fetchOrderSource,
      tableColumns,
      perPage,
      currentPage,
      totalOrderSource,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrderSourceListTable,
      refetchData,
      setOrderSource,
      orderSourceRef,
      // Filter
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
